import React from 'react';
import Popup from 'reactjs-popup';
import ReactHtmlParser from 'react-html-parser';
import MoreIcon from '@material-ui/icons/More';

import './styles.css';

export default (props) => (
  <Popup
    trigger={
    <button className="button-plus">
      Mais sobre
      <MoreIcon/>
    </button>
    }
    modal
    nested
  >
    {close => (
      <div className="wrapper">
        <div className="modal">
          <button className="close" onClick={close}>
            <span>&times;</span>
          </button>
          <div className="header"> Recomendações </div>
          <div className="content">
            {props.recomendacoes === ""
            ? <p>Não há recomendações para esse jogo.</p>
            : ReactHtmlParser (props.recomendacoes)
            }
          </div>
        </div>
      </div>
    )}
  </Popup>
);