import React from 'react';

import './styles.css';

export default function Footer() {
    return (
        <>
        <footer id="main-footer">
            <p>Autoria <strong>Márcia Ambrósio</strong> & <strong>Eduardo Mognon Ferreira</strong>.</p>
            <p>Financiamento: <strong>FAPEMIG</strong> e <strong>UFOP</strong>.</p>
            <p>
                Desenvolvido por 
                <a href="https://www.instagram.com/volutaej/">Voluta Soluções Digitais</a>
                &copy; 2020.
            </p>
        </footer>
        </>
    );
}