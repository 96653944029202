import React from 'react';
import { Bounce } from 'react-reveal';
import { Link } from 'react-router-dom';
import email from '../../assets/imgs/img-home/email.svg';
import facebook from '../../assets/imgs/img-home/facebook.svg';
import instagram from '../../assets/imgs/img-home/instagram.svg';
import seta from '../../assets/imgs/img-home/proximo.svg';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import urlTratador from '../../utils/urlTratador';
import {HiOutlineArrowNarrowRight} from 'react-icons/hi';
import './style.css';
import '../../GlobalStyle.css';

function Home(){

    const history = useHistory();

    const texto = `
    <p>E-portfólio do Corpo Brincante é uma plataforma de aprendizagem virtual com os seguintes 
    objetivos: a) divulgar o material da pesquisa, distribuindo-o publicamente para uso de 
    professores(as) e estudantes; b) revelar as situações de ensino/aprendizagem elaboradas 
    pelos(as) autores(as) desta proposta e envolvidos(as) durante a criação e/ou experimentação 
    dos jogos de tabuleiro; c) criar novas interfaces de aprendizagens com os(as) usuários(as) 
    do website.  Nesta plataforma, estão disponíveis o e-book, os cadernos didáticos, os tabuleiros 
    e as cartas de cada jogo, os vídeos e outras informações. Tais produtos dão visibilidade à criação 
    colaborativa e transdisciplinar do conhecimento dos(as) envolvidos(as) na criação dos jogos, 
    documentando-os em seus diferentes estágios e formatos, para acessos remotos 
    (AMBRÓSIO; FERREIRA, 2020, p.27).</p>

    <p>Referência:</p>
    
    <p>AMBRÓSIO, M.; FERREIRA, E.M. O uso dos jogos de tabuleiro e do E-portfólio Brincante no processo 
    educativo. Curitiba, PR: Editora CRV, 2020.</p>
    `

    return (
        <div className="pagina-home">
            <section id="container-home">
                <section id="parte-inicial">
                <Bounce ssrFadeout left>
                    <div className="introducao">
                        <h1>Seja bem vindo!</h1>
                        { ReactHtmlParser (`${texto.substr(0, 200)}...`) }
                        <button 
                            className="ler-mais"
                            onClick={e => 
                                history.push({
                                    pathname: `/artigo/${urlTratador("O Ebook")}`,
                                    state: {idArtigo: 1}
                                })    
                            }
                        >
                            Continuar leitura
                            <HiOutlineArrowNarrowRight size={20} color="#459DB9"/>
                        </button>
                        <Link to="/projeto" className="container-button">
                                Conheça o projeto
                            <img src={seta} alt=""/> 
                        </Link>
                    </div>
                </Bounce>
                <Bounce ssrFadeout right>
                    <div className="imagem-inicial">
                        <iframe src={`https://www.youtube.com/embed/R0RA-st8qLQ?controls=0`} title="vídeo inicial"></iframe>
                    </div>
                </Bounce>
                </section>
                <Bounce ssrFadeout left>
                    <section id="icons">
                        <a href="mailto:ocorpobrincante@gmail.com?subject=E-Portfolio%20Brincante%20Website"  target="_blanck">
                            <div className="circulo-redes-social">
                                <img src={email} alt="" className="icon"/>
                            </div>
                        </a>
                        <a href="https://www.facebook.com/pg/corpoquebrinca/" target="_blanck">
                            <div className="circulo-redes-social">
                                <img src={facebook} alt="" className="icon"/>
                            </div>
                        </a>
                        <a href="https://www.instagram.com/ocorpobrincante"  target="_blanck">
                            <div className="circulo-redes-social">
                                <img src={instagram} alt="" className="icon"/> 
                            </div>
                        </a>
                    </section>
                </Bounce>
            </section>
        </div>
    );
}

export default Home;