//import ReactDOM from "react-dom";
import React, { Component } from "react";
import {
  Link
} from 'react-router-dom';
import "./styles.css";
import Logo from './Logo';
class NavButton extends Component {
  render() {
    return (
      <Link
        className={
          this.props.active ? "navlink, nav-item nav-link active" : "navlink, nav-item nav-link"
        }
        to={this.props.route}
        onClick={this.props.onClick}
      >
        {" "}
        {this.props.title}
      </Link>
    );
  }
}
class NavBar extends Component {
  state = {
    active: -1
  };
  setActive = i => {
    this.setState({
      active: i
    });
  };
  buttons = [
    {
      title: "O projeto",
      key: 0,
      route: '/projeto',
    },
    {
      title: "E-book",
      key: 1,
      route: '/ebook',
    },
    {
      title: "Jogos",
      key: 2,
      route: '/jogos',
    },
    {
      title: "Artigos",
      key: 3,
      route: '/artigos',
    },
    {
      title: "Vídeos",
      key: 4,
      route: '/videos',
    }
  ];
  render() {
    return (
        <header id="nav-header">
            <section>
              <Link to="/">
                <Logo/>
              </Link>
            </section>
          <nav className="navbar" id="navbarMain">
                  {this.buttons.map(button => (
                    <div className="nav-content" key={button.key}>
                          <NavButton
                            title={button.title}
                            active={this.state.active === button.key}
                            route={button.route}
                            onClick={() => {
                                this.setActive(button.key);
                            }}
                          />
                      </div>
                  ))}
          
            
          </nav>
      </header>
    );
  }
}
export default NavBar;
