import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import './styles.css';
import '../../GlobalStyle.css';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ReactLoading from 'react-loading';

function ConteudoVideo(props){

    const history = useHistory();

    const [ video, setVideo ] = useState('');
    const [ titulo, setTitulo ] = useState('');

    useEffect(() => {
        const id = props.location.state.idVideo;
        api.get(`/video/${id}`)
        .then(response => {
            const [{ video, titulo }] = response.data;
            setVideo(video);
            setTitulo(titulo);
        })
    });

    return (
    <section id="container-videos-um">
        <div class="container-conteudo-videos">
            <div class="secao-texto">
            <h1>{titulo}</h1>
                <button onClick={e => history.push('/videos')}><ArrowBackIcon/>Voltar</button>
            </div>
            <section>
                { video 
                ? <iframe title={titulo} id="video" src={`https://www.youtube.com/embed/${video}?html5=1`} frameBorder="0"></iframe>
                : <ReactLoading className="loading" type="spinningBubbles" color="#6e498f" />
                }
            </section> 
        </div>
    </section>
    );
}

export default ConteudoVideo;