import React from 'react';
import { useHistory } from 'react-router-dom';
import './styles.css';
import urlTratador from '../../../utils/urlTratador';

function ContainerJogo( props ){
    const history = useHistory();
    const idJogo = props.idJogo;

    return (
        <div id="jogos">
            <img src={props.imageGame} alt=""/>
            <section>
                <h2>{props.titulo}</h2>
                <p>{`${props.paragrafo.substr(0, 260)}...`}</p>
                <button
                    onClick={e => history.push({
                        pathname: `/jogo/${urlTratador(props.titulo)}`,
                        state: {idJogo}
                    })}
                    className="container-button"
                >
                    Veja mais
                </button>
            </section>
        </div>
    );
}

export default ContainerJogo;