import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import download from 'downloadjs';

import { Bounce } from 'react-reveal';
import './styles.css';

import Modal from '../../components/Modal';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import GetAppIcon from '@material-ui/icons/GetApp';

import ReactLoading from 'react-loading';

function ConteudoJogos( props ){

    const history = useHistory();

    const [jogoTitulo, setJogoTitulo] = useState('')
    const [jogoDescriao, setDescricaoJogo] = useState('');
    const [jogoImage, setJogoImage] = useState();
    const [jogoRecomendacoes, setJogoRecomendacoes] = useState('');
    const [jogoVideo, setJogoVideo] = useState();
    const [jogoTabuleiro, setJogoTabuleiro] = useState();
    const [jogoCarta, setJogoCarta] = useState();
    const [jogoCaderno, setJogoCaderno] = useState();

    const [ loading, setLoading ] = useState(true);

    async function handleDownload(e, type, fileName) {
        e.preventDefault();
        setLoading(false);
        if(type === 'caderno') {
            const nameFile = 'Cadernos didáticos - o uso dos jogos no processo educativo - Márcia Ambrósio de Eduardo.pdf';
            fetch(`${process.env.REACT_APP_API_BASE_URL}/download/cadernos/Cadernos didáticos.pdf`)
            .then(response => response.blob())
            .then(blob => {
                setLoading(download(blob, nameFile));
            })
            .catch(() => alert('Erro ao fazer download. Tente novamente.'))

            return;
        }
        if(!fileName){
            setLoading(true);
            return alert('Ainda não há arquivo para download.');
        }

        fetch(`${process.env.REACT_APP_API_BASE_URL}/download/${type}/${fileName}`)
        .then((response) => response.blob())
        .then((blob) => {
            //const nomeJogo = urlTratador(jogoTitulo);
            const nameFile = `${type}.${fileName}`;
            setLoading(download(blob, nameFile));
        })
        .catch(() => alert('Erro ao fazer download. Tente novamente.'))
        
    }

    useEffect(() => {
        const id = props.location.state.idJogo;
        api.get(`/jogos/${id}`)
        .then( response => {
            const [{ 
                titulo, 
                descricao, 
                mais, 
                video,
                imagem,
                tabuleiro,
                carta, 
                caderno,
            }] = response.data;
            setJogoTitulo(titulo);
            setDescricaoJogo(descricao)
            setJogoRecomendacoes(mais)
            setJogoImage(imagem)
            setJogoVideo(video)
            setJogoTabuleiro(tabuleiro)
            setJogoCarta(carta)
            setJogoCaderno(caderno)
        })
        .catch( err => alert('Erro ao carregar jogo. Tente novamente.'))
    }, [props.location.state.idJogo]);


    return (
        <section id="container-jogos-um">
            <div className="container-conteudo-jogos">
                <Bounce left ssrFadeout>
                    <section>
                        <div className="secao-texto">
                            <img src ={`${process.env.REACT_APP_API_BASE_URL}/show-static-files/imagens-jogos/${jogoImage}`} alt=""/>
                            <h1>{jogoTitulo}</h1>
                        </div>
                        
                        <p>{jogoDescriao}</p>
                        <div className="cards">
                            <div 
                                className="secao-card" 
                                disabled={!loading }
                                onClick={e => handleDownload(e, 'tabuleiros', jogoTabuleiro)}
                            > 
                                <h2>Tabuleiro</h2>
                                {loading 
                                ? <p>Baixe aqui <GetAppIcon/> </p>
                                : <p>Baixando ...  </p>
                                }
                                
                            </div>
                            <div 
                                className="secao-card" 
                                disabled={!loading }
                                onClick={e => handleDownload(e, 'cartas', jogoCarta)}
                            >  
                                <h2>Cartas</h2>
                                {loading 
                                ? <p>Baixe aqui <GetAppIcon/> </p>
                                : <p>Baixando ...  </p>
                                }
                            </div>
                            <div 
                                className="secao-card" 
                                disabled={!loading }
                                onClick={e => handleDownload(e, 'caderno', jogoCaderno)}
                            > 
                                <h2>Caderno didático</h2>
                                {loading 
                                ? <p>Baixe aqui <GetAppIcon/> </p>
                                : <p>Baixando ...  </p>
                                }
                            </div>
                        </div>
                        <Modal  recomendacoes={jogoRecomendacoes}/>
                    </section>
                </Bounce>
                <Bounce right ssrFadeout>
                    <section>
                        <div className="card-video">
                        { jogoVideo
                        ? <iframe title={jogoTitulo} src={`https://www.youtube.com/embed/${jogoVideo}?controls=0?html5=1`} frameBorder="0"></iframe>
                        : <ReactLoading className="loading" type="spinningBubbles" color="#6e498f" />
                        }
                            
                        </div>
                        <div className="link-video" onClick={e => history.push('/videos')}>
                            <p>Acesse os videos </p>
                            <ArrowRightAltIcon style={{ fontSize: 40 }}/>
                        </div>
                    </section>     
                </Bounce>     
            </div>
        </section>
    );
}

export default ConteudoJogos;