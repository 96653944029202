import React from 'react';
import { 
  Route,
  BrowserRouter
} from 'react-router-dom';
import  './GlobalStyle.css';
import config from 'react-reveal/globals';
import Navbar from './components/Navbar';
import Jogos from './pages/Jogos';
import ConteudoJogos from './pages/Conteudo-Jogos';
import Home from './pages/Home/index';
import Ebook from './pages/Ebook/index';
import Projeto from './pages/Projeto';
import Artigos from './pages/Artigos';
import ArtigosEspecificos from './pages/Artigos-Especificos';
import VideosEspecificos from './pages/Conteudo-Video';
import Videos from './pages/Videos';
import Autores from './pages/Autores';
import Footer from './components/Footer';

export default function App() {
  config({ ssrFadeout: true });
  return (
    <div>
      <BrowserRouter>
        <Navbar/>
        <main id="contente-path" className="page">
          <Route path="/" exact component={Home}/>
          <Route path="/projeto" component={Projeto}/>
          <Route path="/ebook" component={Ebook}/>
          <Route path="/jogos" component={Jogos}/>
          <Route path="/jogo/:jogo" component={ConteudoJogos}/>
          <Route path="/artigos" component={Artigos}/>
          <Route path="/artigo/:artigo" component={ArtigosEspecificos}/>
          <Route path="/videos" component={Videos}/>
          <Route path="/video/:video" component={VideosEspecificos}/>
          <Route path="/autores" component={Autores}/>
        </main>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}