import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import Bounce from 'react-reveal/Bounce';
import './styles.css';
import ContainerJogo from './Jogo';

import ReactLoading from 'react-loading';

export default function Jogos(){

    const [ jogos, setJogos ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        api.get('/jogos')
        .then( response => {
            setJogos(response.data);
            response.data && setLoading(false);
        })
        .catch( err => console.log('Erro!'))
    }, []);      

    return (
        <section id="container-um">
            <div className="container-jogos">
                <h1>Jogos</h1>
                {loading 
                ?   <ReactLoading className="loading" type="spinningBubbles" color="#6e498f" />
                :   jogos.map((jogo, index) => (
                        index % 2 === 0  
                        ? 
                        <Bounce key={jogo.id} left ssrFadeout>
                            <ContainerJogo
                                idJogo={jogo.id}
                                titulo={jogo.titulo}
                                imageGame={`${process.env.REACT_APP_API_BASE_URL}/show-static-files/imagens-jogos/${jogo.imagem}`}
                                paragrafo={jogo.descricao}
                            />
                        </Bounce> 
                        :
                        <Bounce key={jogo.id} right ssrFadeout>
                            <ContainerJogo
                                idJogo={jogo.id}
                                titulo={jogo.titulo}
                                imageGame={`${process.env.REACT_APP_API_BASE_URL}/show-static-files/imagens-jogos/${jogo.imagem}`}
                                paragrafo={jogo.descricao}
                            />
                        </Bounce> 
                    ))
                }   
            </div>
        </section>
    );
}

