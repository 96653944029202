import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'; 
import { Bounce } from 'react-reveal';
import api from '../../services/api';
import urlTratador from '../../utils/urlTratador';

import ReactLoading from 'react-loading';

import './style.css';
import '../../GlobalStyle.css';

function Artigos(){

    const history = useHistory();
    const [artigos, setArtigos] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        api.get('/area-administrativa/artigos')
        .then(response => {
            setArtigos(response.data);
            response.data && setLoading(false);
        })
        .catch( err => console.log('Erro!'))
    }, []);

    return (
    <section id="container-artigos">
        <div className="pagina-artigos">
            <h1>Artigos</h1>
            {loading 
            ? <ReactLoading className="loading" type="spinningBubbles" color="#6e498f" />
            : <div className="bloco-artigos">
                {artigos.map(artigo => (
                    <Bounce bottom ssrFadeout key={artigo.id}>
                        <div className="artigo" onClick={e => history.push({
                            pathname: `/artigo/${urlTratador(artigo.titulo)}`,
                            state: {idArtigo: artigo.id}
                        })}>
                            <div className="artigo-content"> 
                                <>
                                    <h3>{artigo.titulo}</h3>
                                    { ReactHtmlParser(`${artigo.conteudo.substr(0, 100)}...`) }
                                </>
                            </div>  
                        </div>   
                    </Bounce> 
                ))}         
            </div>
            }
            
        </div>
    </section>
    );
}

export default Artigos;