import React from 'react';
import { Bounce } from 'react-reveal';
import seta from '../../assets/imgs/img-home/proximo.svg';
import Imagem01 from '../../assets/imgs/img-projeto/01.jpeg';
import Imagem02  from '../../assets/imgs/img-projeto/02.jpeg';
import Imagem03  from '../../assets/imgs/img-projeto/03.jpeg';
import {HiOutlineArrowNarrowRight} from 'react-icons/hi';
import { useHistory, Link } from 'react-router-dom';
import urlTratador from '../../utils/urlTratador';
import './styles.css';
import '../../GlobalStyle.css';

function Projeto() {

    const history = useHistory();

    const texto = `
    A origem da pesquisa que resultou nesta publicação – ebook, cadernos didáticos 
    de jogos temáticos, vídeos sobre os jogos, site, artigos e outros - que foi 
    motivada pela realização de um projeto de extensão intitulado O Corpo Brincante, 
    as Brincadeiras, as Histórias infantis e o Uso do Portfólio como Processo Avaliativo, 
    para os polos UAB presenciais, do Departamento de Educação e Tecnologias (CEAD/UFOP) 
    e Pró-Reitoria de Extensão (UFOP), a partir de 2015. O projeto desenvolveu práticas 
    pedagógicas numa troca interativa e construtiva com um grupo estudantes dos Cursos de 
    Licenciatura e professores(as) das Redes Municipais, Estaduais, pertencentes a diferentes 
    municípios, onde o CEAD/UFOP tem parcerias com os polos UaB presenciais, com o objetivo 
    de qualificar os(as) participantes para o desenvolvimento de construção dos brinquedos, 
    das brincadeiras, jogos, histórias infantis e registro da experiência vivida, por meio 
    uso de portfólios de aprendizagem na sala de aula. Os cursos ganharam relevância acadêmica 
    com a solicitação de continuidade a posteriori, por diferentes municípios, para a formação 
    continuada dos professores da Rede Pública, vindo a se tornar o projeto de extensão, 
    brevemente relatado neste tópico em que destacamos os diversos momentos de interação – a 
    formação de professores, os números de pessoas atendidas ao longo do ano de 2015 e 2016, 
    os materiais didáticos, produzidos em reflexão a construção e o desenvolvimento do projeto.  
    O projeto ofereceu dois minicursos diferentes: 1) O corpo brincante, as brincadeiras, as 
    histórias infantis e o uso do portfólio como processo avaliativo; 2) Os jogos, a 
    interdisciplinaridade e a prática educativa no ensino das humanidades” 
    (AMBRÓSIO; FERREIRA, 2020, p. 32)
    `
    return (
        <section id="container">
            <div class="container-projeto">

                <div class="secao-texto">
                    <Bounce ssrFadeout left>
                        <h1>Quando tudo começou – do projeto de extensão ao projeto de pesquisa</h1>
                        <p>
                            {`${texto.substr(0, 700)}...`}
                        </p>
                        <button onClick={e => 
                            history.push({
                                pathname: `/artigo/${urlTratador("Quando tudo começou – do projeto de extensão ao projeto de pesquisa")}`,
                                state: {idArtigo: 2}
                            })    
                            }
                        >
                            Continuar leitura
                            <HiOutlineArrowNarrowRight size={20} color="#459DB9"/>
                        </button>
                        <Link to="/autores" className="container-button">
                                Conheça os autores
                                <img src={seta} alt=""/>
                        </Link>
                    </Bounce>
                </div>

                <div class="secao-imagem">
                    <Bounce ssrFadeout right>
                        <img src={Imagem01} alt="orientadora do projeto: Marcia" />
                        <img src={Imagem03} alt="formacao realizada pelo projeto" />
                        <img src={Imagem02} alt="dinamica realizada pelo projeto" />
                    </Bounce>
                </div>
            </div>
        </section>
    );
}

export default Projeto;