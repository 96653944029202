import React, { useState } from 'react';
import { Bounce } from 'react-reveal';
import downloadIcon from '../../assets/imgs/img-ebook/download.svg';
import download from 'downloadjs';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';
import urlTratador from '../../utils/urlTratador';
import {HiOutlineArrowNarrowRight} from 'react-icons/hi';
import './style.css';
import '../../GlobalStyle.css';

function Ebook(){

    const history = useHistory();

    const texto = `
    <p>Esta obra é composta de dois volumes e é fruto dos resultados de uma pesquisa denominada O corpo brincante, o uso dos jogos e do portfólio de aprendizagem no processo educativo, financiada pela Fundação de Amparo à Pesquisa do Estado de Minas Gerais (FAPEMIG), em parceria com a Universidade Federal de Ouro Preto – UFOP (AMBRÓSIO; FERREIRA, 2020, p. 23).<p/>
    <p>O primeiro volume da obra é está dividido em 6 capítulos.</p>
    <p>Na introdução do primerio volume da obra, apresentamos a gênese do projeto de pesquisa O corpo Brincante, os caminhos percorridos, nossos instrumentos de pesquisa, as parcerias construídas, os(as) participantes, os contextos e as intencionalidades, a divisão dos capítulos, os Cadernos Didáticos e a plataforma virtual E-portfólio do Corpo Brincante.</p> 
    <p>No Capítulo 1, discutimos a dimensão política da ação docente brincante. Apontamos a multiplicidade de jogos possíveis nos diversos espaços da ação docente, situando três tipos de jogos principais: o jogo pedagógico, o jogo institucional e o jogo social. Discutimos, também, os reflexos que o acordo econômico neoliberal teve sobre as políticas sociais em escala mundial, situando, em especial, o sistema educacional no Brasil. Estes ensejaran diferentes crises que promoveram paulatinamente a privatização, a mercantilização da educação e a precarização do trabalho docente. E para ressaltar tal realidade, expressamos o quanto, efetivamente, precisamos debater sobre esse cenário, resistir a tais concepções normativas neoliberais e buscar alternativas pedagógicas com vistas à emancipação humana.</p> 
    <p>Na tessitura do Capítulo 2, apresentamos a conceituação dos jogos educativos – os jogos de tabuleiro manuais e os digitais, os de expressão corporal ou de movimento, os competitivos e os cooperativos. Refletimos sobre o uso dos jogos na escola, a ressignificação das práticas curriculares e o papel do professor. </p>
    <p>No Capítulo 3, apresentamos como pode ser o planejamento dos jogos e como jogar com as humanidades. Destacamos algumas conexões reflexivas a respeito do uso dos jogos como recurso didático e de como estes podem se tornar expressão das vivências (realidades cotidianas), sendo, portanto, vias significativas de produção de saber.</p>
    <p>Nos Capítulo 4 e 5, apresentamos os jogos de tabuleiro criados previamente e os construídos pelos(as) participantes, por meio de uma relação pedagógico-dialógica, revelando processos educativos de ensino/aprendizagem – criativos e originais –, enquanto potencializamos a metacognição, desenvolvemos o raciocínio lógico, a linguagem oral e escrita e a análise dos processos históricos dos estudantes, com o uso de estratégias de revisão e reflexão sobre as atividades. Destaca-se o registro qualificado do conhecimento em e-portfólios ou webfólios, o que resulta na elaboração de novos jogos. Assim, apresenta-se, como produto do trabalho, o desenvolvimento um site responsivo que poderá ser utilizado, de forma interativa, entre professores(as), estudantes e pesquisador(a).</p> 
    <p>No Capítulo 6, intitulado de A grande jogada de saberes, registramos nossas conclusões provisórias, destacando cinco pontos relevantes que a prática dos jogos proposta nesta pesquisa estimulou: 1. a reorganização do espaço escolar e uma nova relação com o saber; 2. uma pedagogia do encantamento; 3. uma didática brincante a serviço das aprendizagens; 4. formação para além das fronteiras disciplinares; 5. permanências e/ou mudanças práticas pedagógicas. (AMBRÓSIO; FERREIRA, 2020, p. 24).</p>

    <p>Referência:</p>
    
    <p>AMBRÓSIO, M.; FERREIRA, E.M. O uso dos jogos de tabuleiro e do E-portfólio Brincante no processo educativo. Curitiba, PR: Editora CRV, 2020.</p>
    `

    const [ loading, setLoading ] = useState(true);

    async function handleDownload(e) {
        e.preventDefault();
        setLoading(false);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/download/ebook/ebook.pdf`)
        .then(response => response.blob())
        .then(blob => {
            setLoading(download(blob, 'O uso dos jogos de tabuleiro e do e-portfólio  no processo educativo  - Márcia Ambrósio e Eduardo Mognon.pdf'));
        })
        .catch(() => alert('Erro ao fazer download. Tente novamente.'))
        
    }

    return (
    <section id="container">
        <div className="container-ebook">
            <Bounce ssrFadeout left>
                <div className="secao-texto">
                <h2>E-book</h2>
                        { ReactHtmlParser (`
                        ${texto.substr(0, 500)}...`) }
                        <button 
                            className="ler-mais"
                            onClick={e => 
                                history.push({
                                    pathname: `/artigo/${urlTratador("O Ebook")}`,
                                    state: {idArtigo: 3}
                                })    
                            }
                        >
                            Continuar leitura
                            <HiOutlineArrowNarrowRight size={20} color="#459DB9"/>
                        </button>
                    <div className="container-button">
                        <button onClick={e => handleDownload(e)} disabled={!loading}>
                            {loading 
                                ?   ( <> Baixe o E-book aqui
                                    <img src={downloadIcon} alt=""/> </>)
                                : `Baixando ...`
                                }
                             
                        </button>
                    </div>
                </div>
            </Bounce>
            <Bounce ssrFadeout right>
                <div className="secao-video">
                    <iframe src={`https://www.youtube.com/embed/burT3CC_3bs?controls=0`} title="vídeo ebook"></iframe>
                </div>
            </Bounce>
        </div>
    </section>
    );
}

export default Ebook;