import React from 'react';

import "./styles.css";

export default function Logo() {
    return (
        <div id="main-logo">
            <h1>E-portfólio</h1>
            <h1>Brincante</h1>
        </div>
    );
}