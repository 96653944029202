import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { Bounce } from 'react-reveal';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';
import './style.css';
import '../../GlobalStyle.css';

import ReactLoading from 'react-loading';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function Artigos( props ){
    const history = useHistory();

    const [artigoTitulo, setArtigoTitulo] = useState('');
    const [artigoConteudo, setArtigoConteudo] = useState ('');
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        const id = props.location.state.idArtigo;
        api.get(`/artigos/${id}`)
        .then(response => {
            response.data && setLoading(false);
            const [{ titulo, conteudo }] = response.data;
            setArtigoTitulo(titulo)
            setArtigoConteudo(conteudo)
        })
        .catch( err => alert('Erro ao tentar carregar o artigo. Tente novamente!'))
    }, [props.location.state.idArtigo]);

    return (
    <Bounce ssrFadeout bottom >
        <section id="container-artigos">
                <div class="pagina-artigos">
                {loading 
                ? <ReactLoading className="loading" type="spinningBubbles" color="#6e498f" />
                : 
                    <>
                        <div class="titulo">
                            <h2>{artigoTitulo}</h2>
                            <button onClick={e => history.push('/artigos')}><ArrowBackIcon/>Voltar</button>
                        </div>
                        { ReactHtmlParser (artigoConteudo) }
                    </>
                }
                </div>
        </section>
    </Bounce>
    );
}

export default Artigos;