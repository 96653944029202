import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Video from './Video'
import { Bounce } from 'react-reveal';
import './style.css';
import '../../GlobalStyle.css';

import ReactLoading from 'react-loading';

function Videos(){

    const [videos, setVideos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        api.get('/area-administrativa/categorias-videos')
        .then( response => {
            setCategorias(response.data);
            response.data && setLoading(false);
        });
        api.get(`/videos`)
            .then( response => {
                setVideos(response.data);
        });
    }, []);


    return (
    <section id="container">
        <div className="pagina-videos">
            <h1>Vídeos</h1>
            {loading 
            ?   <ReactLoading className="loading" type="spinningBubbles" color="#6e498f" />
            :   categorias.map(categoria => (
                    <div key={categoria.id}>
                        <h2>{categoria.titulo}</h2>
                        <div id="container-videos">
                            {videos.map(video => (
                                video.categoria_videos_id === categoria.id
                                ?
                                <Bounce bottom ssrFadeout key={video.id}>
                                    <Video 
                                        idVideo={video.id}
                                        nameVideo={video.titulo}
                                        linkVideo={video.video}
                                    />
                                </Bounce>
                                : 
                                <div key={video.id} style={{ display: "none"}}></div>
                            ))}
                        </div>
                        <div id="faixa"></div>
                    </div>  
                ))
            }
                      
        </div>
    </section>
    );
}

export default Videos;