import React from 'react';
import { useHistory } from 'react-router-dom';
import '../style.css';
import '../../../GlobalStyle.css';

function Video( props ){
    const history = useHistory();

    const idVideo = props.idVideo

    return (
        <div className="thumbnail" onClick={e => history.push({
            pathname: `/video/${props.nameVideo}`,
            state: {idVideo}
        })}>
            <img src={`http://img.youtube.com/vi/${props.linkVideo}/mqdefault.jpg`} alt={props.nameVideo + "thumbnail"}></img>
            <p>{props.nameVideo}</p>
        </div>
    );
}

export default Video;