import React from 'react';
import { Bounce } from 'react-reveal';
import Marcia from '../../assets/imgs/img-autores/marcia.jpeg';
import Eduardo from '../../assets/imgs/img-autores/eduardo.jpeg';

import './styles.css';

function Autores() {
    return (
        <>
            <div className="title-autores">
                <h3>Autores do projeto</h3>
            </div>
            <section id="autores">
                <div>
                    <Bounce ssrFadeout left>
                        <img src={Marcia} alt="autora do projeto, Maria"/>
                    </Bounce>
                    <Bounce ssrFadeout right>
                        <p>
                            <span>
                                <strong>Márcia Ambrósio</strong> é Doutora em Educação pela Universidade Federal de Minas Gerais (UFMG), 
                                com Pós-Doutorado pela Universidade de Barcelona. Leciona como Professora Assistente no 
                                DEETE/UFOP. Seus temas de estudos e pesquisas no CNPq e FAPEMIG, que resultaram em 
                                publicações em livros, artigos e cadernosdidáticos, são os seguintes:      
                                avaliação/autoavaliação, portfólio/e-portfólio/, metacognição no ensino superior, 
                                mediação tecnológica, relação pedagógica no AVA, jogos didáticos, dentre outros.
                                Outras informações e contatos:
                            </span>
                            
                                <a href="http://lattes.cnpq.br/5989203362946532" target="_blank" rel="noopener noreferrer">
                                    Currículo Lattes
                                </a>
                                            
                                <a href="http://www.researcherid.com/rid/B-7320-2018" target="_blank" rel="noopener noreferrer">
                                    Publons
                                </a>
                        
                                <a href="https://orcid.org/0000-0002-2354-8306" target="_blank" rel="noopener noreferrer">
                                    Orcid
                                </a>
                                            
                                <a href="https://www.facebook.com/marciambrosio" target="_blank" rel="noopener noreferrer">
                                    Facebook
                                </a>
                            
                                <a href="https://www.youtube.com/user/marciaambrosior/" target="_blank" rel="noopener noreferrer">
                                    Youtube
                                </a>
                            
                                <a href="http://portfoliodeaprendizagem.blogspot.com.br" target="_blank" rel="noopener noreferrer">
                                    Blog
                                </a>
                            

                            <span>Telefones: (31) 3559 1354 (DEETE/CEAD/UFOP)</span>
                            <span>E-mail: marcia.rezende@ufop.edu.br</span>
                        </p>
                    </Bounce>
                </div>
                <div>
                    <Bounce ssrFadeout left>
                        <p>
                            <span>
                                <strong>Eduardo Mognon Ferreira</strong> é Mestre em Ensino de História pela Universidade Federal de Ouro 
                                Preto.  Suas pesquisas e publicações estão centradas na área de Educação, com ênfase em 
                                Ensino-Aprendizagem e o uso dos jogos como recursos didáticos interdisciplinares no Ensino 
                                da História da África. É, atualmente, pesquisador da FAPEMIG e criou jogos de tabuleiro que 
                                discutem o Feudalismo, a Primeira Guerra Mundial, Sustentalibiliade e Gênero.
                            </span>

                            <a href="http://lattes.cnpq.br/2597430215828383" target="_blank" rel="noopener noreferrer">
                                Currículo Lattes
                            </a>
                            
                        </p>
                    </Bounce>
                    <Bounce ssrFadeout right>
                        <img src={Eduardo} alt="autor do projeto, Eduardo"/>
                    </Bounce>
                </div>
            </section>
        </>
    );
}

export default Autores;